/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
    background: rgb(255, 255, 255);
    color: rgb(51, 51, 51);
    font-family: gothic, メイリオ, Meiryo, 游ゴシック体, "游ゴシック Medium", "Yu Gothic", "Yu Gothic Medium", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", YuGothic, Helvetica, sans-serif;
    overflow-x: hidden;
    width: 100%;
}

@font-face {
    font-family: 'gothic';
    src: url('./fonts/gothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
    box-sizing: border-box;
    width: 100%;
}

*, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}